//@import 'src/stylesheets/fonts';

@font-face {
  font-display: swap;
  font-family: 'MuseoSans';
  font-weight: 300;
  src: url('../src/fonts/museosans/MuseoSans300.woff'),
       url('../src/fonts/museosans/MuseoSans300.woff2')
}

@font-face {
  font-display: swap;
  font-family: 'MuseoSans';
  font-weight: 500;
  src: url('../src/fonts/museosans/MuseoSans500.woff'),
       url('../src/fonts/museosans/MuseoSans500.woff2')
}

@font-face {
  font-display: swap;
  font-family: 'MuseoSans';
  font-weight: 900;
  src: url('../src/fonts/museosans/MuseoSans900.woff'),
       url('../src/fonts/museosans/MuseoSans900.woff2')
}


//@import url('./src/fonts/museosans/MuseoSans300.woff');
//@import url('./src/fonts/museosans/MuseoSans300.woff2');
//@import url('./src/fonts/museosans/MuseoSans500.woff');
//@import url('./src/fonts/museosans/MuseoSans500.woff2');
//@import url('./src/fonts/museosans/MuseoSans900.woff');
//@import url('./src/fonts/museosans/MuseoSans900.woff2');
